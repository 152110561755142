import React, { useState } from 'react';
import ReceiptForm from './ReceiptForm';
import ReceiptItemList from './ReceiptItemList';
import database from './firebase';
import { collection, addDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';

function Home() {
  const [receiptItems, setReceiptItems] = useState([]);
  const [venmoAccount, setVenmoAccount] = useState('');
  const navigate = useNavigate();

  const addItem = (item) => {
    setReceiptItems([...receiptItems, item]);
  };

  const removeItem = (index) => {
    setReceiptItems(receiptItems.filter((_, i) => i !== index));
  };

async function saveReceipt(receiptData) {
  try {
    const receiptsCollection = collection(database, "receipts");
    const receiptRef = await addDoc(receiptsCollection, { items: receiptData, venmoAccount });
    console.log("Receipt saved with ID:", receiptRef.id);
    navigate(`receipt/${receiptRef.id}`);
  } catch (e) {
    console.error("Error adding receipt:", e);
  }
}

  return (
    <div className="container">
      <h1 className="text-center my-4">Dine Divide</h1>
      <h2 className="text-center my-4">Enter Receipt Items:</h2>
      <ReceiptForm addItem={addItem} />
      <ReceiptItemList items={receiptItems} removeItem={removeItem} />
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Your Venmo Account (optional)"
          value={venmoAccount}
          onChange={(e) => setVenmoAccount(e.target.value)}
        />
      </div>
      {receiptItems.length > 0 && (
        <button className="btn btn-success mt-3" onClick={() => saveReceipt(receiptItems)}>
            Done
        </button>
      )}
    </div>
  );
}

export default Home;
