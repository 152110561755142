import React from 'react';
import ReceiptItem from './ReceiptItem';

function ReceiptItemList({ items, removeItem, readOnly = false, onSelect }) {
  const totalSum = items.reduce((sum, item) => {
    const taxAmount = parseFloat(item.price) * (parseFloat(item.tax) / 100);
    const tipAmount = (parseFloat(item.price) + taxAmount) * (parseFloat(item.tip) / 100);
    const itemTotal = parseFloat(item.price) + taxAmount + tipAmount;
    return sum + itemTotal;
  }, 0);

  return (
    <div className="card mt-4">
      <div className="card-header">Receipt Items</div>
      <ul className="list-group list-group-flush">
        {items.map((item, index) => (
          <ReceiptItem
            key={index}
            item={item}
            index={index}
            removeItem={removeItem}
            readOnly={readOnly}
            onSelect={onSelect}
          />
        ))}
      </ul>
      <div className="card-footer">
        <p className="text-center my-3">Total: ${totalSum.toFixed(2)}</p>
      </div>
    </div>
  );
}

export default ReceiptItemList;
