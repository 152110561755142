export const trackPageview = (url) => {
    if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
      window.gtag('config', 'G-QJ8TLH35MR', {
        page_path: url,
      });
    }
  };
  
  export const trackEvent = (event_name, event_params) => {
    if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
      window.gtag('event', event_name, event_params);
    }
  };