// ReceiptForm.js
import React, { useState } from 'react';

function ReceiptForm({ addItem }) {
  const [item, setItem] = useState('');
  const [price, setPrice] = useState('');
  const [tax, setTax] = useState('');
  const [tip, setTip] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    addItem({ item, price, tax, tip });
    setItem('');
    setPrice('');
  };

  return (
    <form onSubmit={handleSubmit} className="mb-4">
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Item"
          value={item}
          onChange={(e) => setItem(e.target.value)}
          required
        />
        <input
          type="number"
          className="form-control"
          placeholder="Price"
          step="0.01"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          required
        />
      </div>
      <div className="input-group mb-3">
        <input
          type="number"
          className="form-control"
          placeholder="Tax %"
          step="0.01"
          value={tax}
          onChange={(e) => setTax(e.target.value)}
          required
        />
        <input
          type="number"
          className="form-control"
          placeholder="Tip %"
          step="0.01"
          value={tip}
          onChange={(e) => setTip(e.target.value)}
          required
        />
      </div>
      <button type="submit" className="btn btn-primary">
        Add Item
      </button>
    </form>
  );
}

export default ReceiptForm;
