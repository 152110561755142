import React from 'react';
import './index.css';

function ReceiptItem({ item, index, removeItem, readOnly = false, onSelect }) {
  const { item: itemName, price, tax, tip } = item;

  const calculateTotal = () => {
    const taxAmount = parseFloat(price) * (parseFloat(tax) / 100);
    const tipAmount = (parseFloat(price) + taxAmount) * (parseFloat(tip) / 100);
    return (parseFloat(price) + taxAmount + tipAmount).toFixed(2);
  };

  const total = calculateTotal();

  const handleSelect = (e) => {
    if (onSelect) {
      onSelect(index, { ...item, total }, e.target.checked);
    }
  };

  return (
    <li className={`list-group-item d-flex justify-content-between ${onSelect && readOnly && 'selectable'}`}>
      <div>
        <strong>{itemName}</strong>
        <p className="mb-0">
          Price: ${price} | Tax: {tax}% | Tip: {tip}%
        </p>
      </div>
      <div>
        <strong>Total: ${total}</strong>
        {!readOnly && (
          <button className="btn btn-danger btn-sm ms-3" onClick={() => removeItem(index)}>
            Remove
          </button>
        )}
        {readOnly && (
          <input
            type="checkbox"
            className="form-check-input ms-3"
            onChange={handleSelect}
          />
        )}
      </div>
    </li>
  );
}

export default ReceiptItem;
