import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReceiptItemList from './ReceiptItemList';
import database from './firebase';
import { collection, doc, getDoc } from "firebase/firestore";

function ReceiptReadOnly() {
  const { id } = useParams();
  const [receiptItems, setReceiptItems] = useState([]);
  const [venmoAccount, setVenmoAccount] = useState('');
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [selectedTotal, setSelectedTotal] = useState(0);

  const handleItemSelect = (index, item, isSelected) => {
    const newSelectedItems = new Set(selectedItems);
    if (isSelected) {
      newSelectedItems.add(index);
      setSelectedTotal((prevTotal) => prevTotal + parseFloat(item.total));
    } else {
      newSelectedItems.delete(index);
      setSelectedTotal((prevTotal) => prevTotal - parseFloat(item.total));
    }
    setSelectedItems(newSelectedItems);
  };

  const generateNote = (selectedItems) => {
    const selectedItemsList = Array.from(selectedItems)
      .map((index) => receiptItems[index].item)
      .join(', ');

    return `via DineDivide.com - ${selectedItemsList}`;
  };

  const generateVenmoLink = (venmoAccount, transactionType, amount, note) => {
    const baseUrl = `https://venmo.com/${venmoAccount}`;
    const queryParams = [
      `txn=${transactionType}`,
      `amount=${amount}`,
      `note=${encodeURIComponent(note)}`,
    ].join('&');

    return `${baseUrl}?${queryParams}`;
  };

  const venmoLink =
    receiptItems.length > 0 &&
    generateVenmoLink(
      venmoAccount,
      'pay',
      selectedTotal.toFixed(2),
      generateNote(selectedItems)
    );

  useEffect(() => {
    async function fetchReceipt() {
      const receiptRef = doc(database, "receipts", id);
      const receiptDoc = await getDoc(receiptRef);
      if (receiptDoc.exists()) {
        const data = receiptDoc.data().items.map(item => ({
          item: item.item,
          price: item.price,
          tax: item.tax,
          tip: item.tip,
        }));
        setReceiptItems(data);
        console.log(receiptDoc.data()['venmoAccount']);
        setVenmoAccount(receiptDoc.data()['venmoAccount']);
      } else {
        console.error("No receipt found with ID:", id);
      }
    }

    fetchReceipt();
  }, [id]);

  const shareReceipt = () => {
    const url = window.location.href;

    if (navigator.clipboard) {
      navigator.clipboard.writeText(url).then(() => {
      alert('Receipt link copied to clipboard');
      }).catch((err) => {
      console.error('Error copying receipt link:', err);
      });
      } else {
      // Fallback method using a temporary textarea element
      const textarea = document.createElement('textarea');
      textarea.value = url;
      document.body.appendChild(textarea);
      textarea.select();
      try {
      document.execCommand('copy');
      alert('Receipt link copied to clipboard');
      } catch (err) {
      console.error('Error copying receipt link:', err);
      } finally {
      document.body.removeChild(textarea);
      }
      }
      };
      
      return (
      <div className="container">
      <h1 className="text-center my-4">Dine Divide</h1>
      <h2 className="text-center my-4">Select Items You Purchased</h2>
      <ReceiptItemList items={receiptItems} readOnly={true} onSelect={handleItemSelect} />
      <div className="mt-3">
      <strong>Selected total: ${selectedTotal.toFixed(2)}</strong>
      </div>
      {receiptItems.length > 0 && (
      <a
             href={venmoLink}
             className="btn btn-primary mt-3"
             target="_blank"
             rel="noopener noreferrer"
           >
      Pay via Venmo
      </a>
      )}
      <button className="btn btn-primary mt-3" onClick={shareReceipt}>
      Share
      </button>
      </div>
      );
      }
      
      export default ReceiptReadOnly;
