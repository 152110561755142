import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './Home';
import ReceiptReadOnly from './ReceiptReadOnly';
import useTrackPageview from './useTrackPageview';

function App() {
  useTrackPageview();

  return (
    <div className="container">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/receipt/:id" element={<ReceiptReadOnly />} />
      </Routes>
    </div>
  );
}

export default App;
