// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBDYva8dBqDaTh-4AtH8HMQMQysCzu20Uo",
    authDomain: "billsplit-aa8a9.firebaseapp.com",
    projectId: "billsplit-aa8a9",
    storageBucket: "billsplit-aa8a9.appspot.com",
    messagingSenderId: "166539402799",
    appId: "1:166539402799:web:d15647c51b7c50614fb0da",
    measurementId: "G-QJ8TLH35MR"
};

const app = initializeApp(firebaseConfig);
const database = getFirestore(app);

export default database;
